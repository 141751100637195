
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.72.1 (dbebc6274009f7fff9d1158f589ed089)",
    buildSeed: 1641894173677,
    "wdosbox.wasm": {
        "size": 1480060,
        "gzSize": 499012
    },
    "wdosbox.js": {
        "size": 129175,
        "gzSize": 33678
    },
    "wlibzip.wasm": {
        "size": 112343,
        "gzSize": 54127
    },
    "wlibzip.js": {
        "size": 80579,
        "gzSize": 20779
    }
,
};
